import { captureError } from '../../debug/capture-error';
import { Interceptor } from './interceptor';

type FetchParams = [input: string | URL, init?: RequestInit];
export type Callback = (
  request: FetchParams,
  onComplete: Promise<unknown>
) => Promise<FetchParams | Response>;

export type InterceptPredicate = (
  input: string | URL,
  init?: RequestInit
) => boolean;

export class RequestInterceptor extends Interceptor<FetchParams, Response> {
  constructor(private predicate: InterceptPredicate) {
    function terminateCallbacks(args: FetchParams | Response) {
      return args instanceof Response;
    }

    super(terminateCallbacks);

    const chain = this.chainHandlers.bind(this);

    interceptFetch(predicate, chain);
    interceptXHR(predicate, chain);
  }
}

interface InterceptingXHR extends XMLHttpRequest {
  _pdOpenArgs?: Parameters<XMLHttpRequest['open']>;
  _pdOpenIntercept?: Promise<void>;
  _pdRequestHeaders?: [string, string][];
}

function interceptFetch(shouldIntercept: InterceptPredicate, cb: Callback) {
  const origFetch = window.fetch;
  window.fetch = function fetch(
    input: RequestInfo | URL,
    init?: RequestInit
  ): Promise<Response> {
    try {
      let request: FetchParams;

      if (input instanceof Request) {
        request = [input.url, init];
      } else {
        request = [input, init];
      }

      if (shouldIntercept(...request)) {
        let resolveOnComplete: () => void;
        const onComplete = new Promise<void>((resolve) => {
          resolveOnComplete = resolve;
        });

        return cb(request, onComplete)
          .then((newRequest) => {
            if (newRequest instanceof Response) {
              return newRequest;
            }

            return origFetch(...newRequest).then((res) => {
              resolveOnComplete();
              return res;
            });
          })
          .catch(() => {
            return origFetch(input, init);
          }); // TODO: reject the onComplete Promise?
      }
    } catch (error) {
      void captureError(error);
    }

    return origFetch(input, init);
  };
}

function interceptXHR(shouldIntercept: InterceptPredicate, cb: Callback) {
  const origOpen = window.XMLHttpRequest.prototype.open;

  XMLHttpRequest.prototype.open = function open(
    this: InterceptingXHR,
    method: string,
    url: string | URL,
    async = true,
    user?: string,
    password?: string
  ) {
    // Setup some variables to store things in between calls
    this._pdOpenArgs = undefined;
    this._pdOpenIntercept = undefined;
    this._pdRequestHeaders = undefined;

    const pdOpen: Parameters<XMLHttpRequest['open']> = [
      method,
      url,
      async ?? true,
      user,
      password,
    ];

    // Intercepting GET XHRs is possible but complicated.
    // If possible stores should be updated to use POST which is more correct anyway.
    if (method.toUpperCase() === 'GET') {
      if (shouldIntercept(url, { method })) {
        // biome-ignore lint/suspicious/noConsole: log
        console.warn('GET XHRs are not fully supported by PurpleDot.');
      }
    }

    // We store the open args because we'll need them again when intercepting in send()
    this._pdOpenArgs = pdOpen;

    // For other methods we don't intercept until send() is called
    origOpen.apply(this, pdOpen);
  };

  const origSend = window.XMLHttpRequest.prototype.send;

  XMLHttpRequest.prototype.send = function send(
    this: InterceptingXHR,
    body?: XMLHttpRequestBodyInit | null // TODO: This can also be a Document
  ) {
    // _pdOpen is only set if the request might still need intercepting in send()
    if (this._pdOpenArgs != null) {
      const pdOpen = this._pdOpenArgs;

      try {
        const request: FetchParams = [
          pdOpen[1],
          {
            method: pdOpen[0],
            body,
          },
        ];

        if (shouldIntercept(...request)) {
          let resolveOnComplete: () => void;
          const onComplete = new Promise<void>((resolve) => {
            resolveOnComplete = resolve;
          });
          this.addEventListener('loadend', () => {
            resolveOnComplete();
          });

          cb(request, onComplete)
            .then((newRequest) => {
              if (newRequest instanceof Response) {
                if (!newRequest.ok) {
                  this.dispatchEvent(new Event('error', {}));
                }

                return;
              }

              const [input, init] = newRequest;

              // TODO: Check that init has the same method as the original request
              pdOpen[1] = input.toString();

              // TODO: This can actually be a ReadableStream which XHR does not support.
              origSend.call(this, init?.body as XMLHttpRequestBodyInit);
            })
            .catch(() => {
              origSend.call(this, body);
              // TODO: reject the onComplete Promise?
            });

          return;
        }
      } catch (error) {
        void captureError(error);
      }
    }

    // If the open() interceptor is still running. Let it finish first.
    const XHR_READY_STATE_UNSENT = 0;
    if (
      this.readyState === XHR_READY_STATE_UNSENT &&
      this._pdOpenIntercept != null
    ) {
      this._pdOpenIntercept
        .then(() => {
          origSend.call(this, body);
        })
        .catch(() => {
          origSend.call(this, body);
        });
    } else {
      origSend.call(this, body);
    }
  };
}
