export function isFBM() {
  return (
    window.Shopify.shop === 'stg-fbm.myshopify.com' ||
    window.Shopify.shop === 'd6dec8-2.myshopify.com'
  );
}

export function isLRG() {
  return (
    window.location.href.includes('limitedrungames') ||
    window.location.href.includes('limited-run-games')
  );
}

export function isRestDuvet() {
  return (
    window.location.href.includes('restduvet') ||
    window.location.href.includes('getrest')
  );
}

export function isLisaSaysGah() {
  return window.Shopify?.shop === 'lisasays-gah.myshopify.com';
}

export function isDollsKill() {
  return window.Shopify?.shop === 'dolls-test.myshopify.com';
}

export function isVoited() {
  return [
    'voited-eu.myshopify.com',
    'voited-de.myshopify.com',
    'voited-fr.myshopify.com',
    'voited-uk.myshopify.com',
    'voited1.myshopify.com',
  ].includes(window.Shopify?.shop ?? '');
}
