import { AddToCartForm } from '../shopify-theme/add-to-cart-form';
import { ButtonIframe } from './button-iframe';

class NotifyMe {
  show(buttonIframe: ButtonIframe, addToCartForm: AddToCartForm) {
    let el = document.querySelector<HTMLElement>('#pd-notify-me');
    if (!el) {
      el = document.createElement('p');
      el.id = 'pd-notify-me';
      el.style.textDecoration = 'underline';
      el.innerHTML = '<a href="">Notify me when in stock</a>';
      el.addEventListener('click', (ev) => {
        ev.preventDefault();
        buttonIframe.onNotifyMeClicked();
      });

      const brandingElement = addToCartForm.querySelector('#pd-branding');
      brandingElement?.parentElement?.insertBefore(el, brandingElement);
    } else {
      el.style.display = '';
    }
  }

  hide() {
    const el = document.querySelector<HTMLElement>('#pd-notify-me');
    if (el) {
      el.style.display = 'none';
    }
  }
}

export { NotifyMe };
