import i18next, { CallbackError, ResourceKey } from 'i18next';
import { baseConfig, loadAsync } from './';
import { autoDetectLocale } from './locale';

// Configure a backend that loads translations asynchronously.
// This works using webpack code splitting to load translations on demand.
// We only load the badly named "shopify" namespace because it contains
// the specific frontend translations.

const i18nLoading = i18next.isInitialized
  ? Promise.resolve()
  : i18next
      .use({
        type: 'backend',
        read(
          language: string,
          namespace: string,
          callback: (
            err: CallbackError,
            data: ResourceKey | boolean | null | undefined
          ) => void
        ) {
          loadAsync(language, namespace)
            .then((resources) => {
              callback(null, resources);
            })
            .catch((error) => {
              callback(error, null);
            });
        },
      })
      .use({
        type: 'languageDetector',
        detect() {
          return autoDetectLocale();
        },
      })
      .init({
        ...baseConfig,
        ns: ['shopify'],
        lng: undefined,
      });

// Give the translations up to 5 seconds to load before giving up and just rendering anyway

export const i18nReady = Promise.race([
  i18nLoading.catch((error) => {
    // biome-ignore lint/suspicious/noConsole: log
    console.error(error);
  }),
  new Promise((resolve) => {
    setTimeout(resolve, 5000);
  }),
]);

export const t = i18next.getFixedT(null, 'shopify');
export const addResourceBundle = i18next.addResourceBundle.bind(i18next);
