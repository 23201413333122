type InterceptHandler<Req, Resp> = (
  request: Req,
  onComplete: Promise<unknown>
) => Promise<Req | Resp>;

export abstract class Interceptor<
  Req,
  Resp extends Response = never,
  H extends InterceptHandler<Req, Resp> = InterceptHandler<Req, Resp>,
> {
  constructor(private terminateCallbacks?: (args: Req) => boolean) {}

  private callbacks: H[] = [];

  addHandler(...cb: H[]) {
    this.callbacks.push(...cb);
  }

  protected async chainHandlers<R extends Parameters<H>>(...request: R) {
    let [newRequest] = request;
    const responsePromise = request[1];

    for await (const cb of this.callbacks) {
      if (this.terminateCallbacks?.(newRequest)) {
        return newRequest;
      }

      const result = await cb(newRequest, responsePromise);

      if (result instanceof Response) {
        return result;
      }
      newRequest = result;
    }

    return newRequest;
  }
}

export interface CartItem {
  id: number;
  properties?: { [key: string]: string };
  selling_plan?: number | null;
}

export type AddToCartJSON =
  | (CartItem & { checkout?: string })
  | {
      items: CartItem[];
    };

export function parseRequestBody(
  init: RequestInit
): FormData | URLSearchParams | AddToCartJSON {
  if (typeof init.body === 'string') {
    const headers = new Headers(init.headers);
    const contentType = headers.get('content-type');

    if (contentType === 'application/json') {
      return JSON.parse(init.body);
    }

    if (contentType === 'application/x-www-form-urlencoded') {
      return new URLSearchParams(init.body);
    }

    try {
      return JSON.parse(init.body);
    } catch {
      return new URLSearchParams(init.body);
    }
  }

  if (init.body instanceof FormData || init.body instanceof URLSearchParams) {
    return init.body;
  }

  // This could happen if they try to post a Blob, ReadableStream etc.
  // This seems fairly unlikely so we don't implement it.

  throw new TypeError('request body could not be parsed');
}

export function makeRequestBody(
  init: RequestInit,
  body: ReturnType<typeof parseRequestBody>
): URLSearchParams | FormData | string {
  if (typeof init.body === 'string' && typeof body !== 'string') {
    if (body instanceof URLSearchParams || body instanceof FormData) {
      return body.toString();
    }
    return JSON.stringify(body);
  }

  if (
    body instanceof URLSearchParams ||
    body instanceof FormData ||
    typeof body === 'string'
  ) {
    return body;
  }

  // This should really never happen since we decoded it in the first place.

  throw new Error(`unable to encode body: ${typeof body} ${body.toString()}`);
}

export function shopifyUrlStartsWith(url: URL | string, prefix: string) {
  const parsedURL = new URL(url, window.location.href);

  return (
    parsedURL.pathname.startsWith(`/${prefix}`) ||
    parsedURL.pathname.startsWith(
      `${window?.Shopify?.routes?.root ?? '/'}${prefix}`
    )
  );
}
