import { isFBM, isRestDuvet } from '../purple-dot-integration/brands';
import { AddToCartButton } from './add-to-cart-button';
import { isVisible } from './is-visible';
import { LineItemProperties } from './line-item-properties';
import { SellingPlan } from './selling-plan';

// Pro
import * as UrlUtils from './url-utils';

/**
 * AddToCartForm
 *
 * Represents the form element used in all Shopify Themes
 * used to add items to the cart.
 *
 * It can be identified by form[action="/cart/add"] and is
 * primarily seen on Product Pages but can be seen in quick view modals
 * or quick buy options as well.
 *
 * This object provides easier access for querying the AddToCartForm
 * in order to find things like the button, properties elements, etc.
 */

type VariantIdListener = (variantId?: number | null) => void;

class AddToCartForm {
  el: HTMLElement;
  variantIdListeners: VariantIdListener[];
  selectedVariant!: SelectedVariant;
  currentVariantId: number | null;
  addToCartButton: AddToCartButton | null;
  addToCartButtonSelector?: string;

  constructor({
    el,
    selectedVariantSelector,
    addToCartButtonSelector,
  }: {
    el: HTMLElement;
    selectedVariantSelector?: string;
    addToCartButtonSelector?: string;
  }) {
    this.el = el;
    this.variantIdListeners = [];
    this.selectedVariant = new SelectedVariant(el, selectedVariantSelector);
    this.currentVariantId = this.selectedVariant.getVariantId();
    this.addToCartButton = null;
    this.addToCartButtonSelector = addToCartButtonSelector;

    setInterval(() => {
      const newVariantId = this.selectedVariant.getVariantId();
      if (newVariantId !== this.currentVariantId) {
        if (isRestDuvet() || isFBM()) {
          for (const cb of this.variantIdListeners) {
            cb(newVariantId);
          }
        } else {
          if (newVariantId) {
            for (const cb of this.variantIdListeners) {
              cb(newVariantId);
            }
          }
        }

        this.currentVariantId = newVariantId;
      }
    }, 10);
  }

  querySelector(selector: string): HTMLElement | null {
    return this.el.querySelector(selector);
  }

  querySelectorAll(selector: string): NodeListOf<HTMLElement> {
    return this.el.querySelectorAll(selector);
  }

  /**
   * querySelectorSurroundingElements
   *
   * Sometimes, we need to access elements related
   * to the product but not inside the AddToCartForm.
   * e.g for product price or if some products show specific messages
   *
   * In these cases we can recursively search outside
   * the form until we can find the element
   */
  querySelectorSurroundingElements(selector: string): HTMLElement | null {
    let baseElem: HTMLElement = this.el;
    let foundElem: HTMLElement | null = null;

    for (let distance = 0; distance < 5 && !foundElem; distance += 1) {
      foundElem = baseElem.querySelector(selector);

      if (baseElem.parentElement) {
        baseElem = baseElem.parentElement;
      } else {
        break;
      }
    }

    return foundElem;
  }

  getAddToCartButton() {
    if (
      !this.addToCartButton?.getElement()?.isConnected ||
      !(this.addToCartButton && isVisible(this.addToCartButton?.getElement()))
    ) {
      let buttonEl: HTMLButtonElement | null = null;

      const selectors = [
        '[type="submit"]',
        '[name="submit"]',
        '[class*="submit"]',
        'button[class*="add-to-cart"]',
        'button[id*="basket"]',
      ];
      const negativeSelector = '.faux';

      if (this.addToCartButtonSelector) {
        selectors.unshift(this.addToCartButtonSelector);
      }

      for (const selector of selectors) {
        const buttonEls = this.el.querySelectorAll<HTMLButtonElement>(
          `${selector}:not(${negativeSelector})`
        );
        buttonEl = findLastVisible(buttonEls) ?? last(buttonEls) ?? null;
        if (buttonEl) {
          break;
        }
      }

      if (!buttonEl && this.el.tagName === 'FORM' && this.el.id) {
        buttonEl = document.querySelector<HTMLButtonElement>(
          `[type="submit"][form="${CSS.escape(this.el.id)}"]`
        );
      }

      if (buttonEl) {
        this.addToCartButton?.disconnect();

        this.addToCartButton = new AddToCartButton({ el: buttonEl });
      }
    }

    return this.addToCartButton;
  }

  getLineItemProperties() {
    return new LineItemProperties({ form: this.el });
  }

  getSellingPlan() {
    return new SellingPlan({ form: this.el });
  }

  getElement() {
    return this.el;
  }

  onVariantIdChange(cb: VariantIdListener) {
    this.variantIdListeners.push(cb);
  }

  getVariantId() {
    return this.selectedVariant.getVariantId();
  }

  getHandle() {
    const atcFormsOnPage = document.querySelectorAll(
      'form[action$="/cart/add"]'
    );

    if (atcFormsOnPage.length > 0) {
      const domHandleElement = this.el.closest<HTMLElement>(
        '*[data-product-handle]'
      );

      if (domHandleElement) {
        return domHandleElement.dataset.productHandle?.replaceAll(
          /[(){}[\]]/g,
          ''
        );
      }

      const domProductHref =
        this.el.parentNode?.querySelector<HTMLAnchorElement>(
          'a[href*="/products/"]'
        );

      if (
        domProductHref &&
        domProductHref.hostname === window.location.hostname
      ) {
        return UrlUtils.extractHandle(domProductHref.href);
      }
    }

    return UrlUtils.extractHandle(window.location.href);
  }

  observe(cb: (mutationList: MutationRecord[]) => void) {
    const observer = new MutationObserver(cb);

    observer.observe(this.el, {
      subtree: true,
      childList: true,
    });
    return observer;
  }
}

class SelectedVariant {
  rootEl: HTMLElement;
  selectedVariantSelector: string;
  loggedWarning = false;

  constructor(rootEl: HTMLElement, selectedVariantSelector?: string) {
    this.rootEl = rootEl;
    this.selectedVariantSelector =
      selectedVariantSelector ?? '[name="id"],[name="id[]"]';
  }

  getVariantId(): number | null {
    if (isRestDuvet()) {
      return this.findVariantIdForRestDuvet() ?? null;
    }

    const value = this.getElementValue();

    if (!value) {
      return null;
    }

    if (!value.match(/^\d+$/)) {
      throw new Error(
        `Cannot get variant id from selector - expected an id, got ${value}`
      );
    }

    return Number.parseInt(value, 10);
  }

  private findVariantIdForRestDuvet() {
    const variantRadios = document.querySelector<HTMLElement>('variant-radios');
    if (!variantRadios) {
      return;
    }

    const variantRadiosData = variantRadios.querySelector<HTMLElement>(
      '[type="application/json"]'
    );
    if (!variantRadiosData) {
      return;
    }

    const variants = JSON.parse(variantRadiosData.textContent ?? '') as {
      // TODO: Create/use better Types
      id: number;
      options: string[];
    }[];
    const found = variants.find((variant) => {
      return !variant.options
        .map((option, index) => {
          const fieldsets = Array.from(
            variantRadios.querySelectorAll('fieldset')
          );
          const options = fieldsets.map((fieldset) => {
            const inputs = fieldset.querySelectorAll('input');
            if (!inputs) {
              return;
            }
            return Array.from(inputs).find((radio) => radio.checked)?.value;
          });

          return options[index] === option;
        })
        .includes(false);
    });

    if (!found) {
      return null;
    }

    return found.id;
  }

  private getElementValue() {
    const el = this.rootEl.querySelector<HTMLElement>(
      this.selectedVariantSelector
    );

    if (!el) {
      try {
        const formData = new FormData(this.rootEl as HTMLFormElement);
        const variantId = formData.get('id') || formData.get('items[0][id]');

        if (variantId != null) {
          return variantId as string;
        }
      } catch {
        // Oh well, guess we couldn't find the variant id
      }

      if (!this.loggedWarning) {
        // biome-ignore lint/suspicious/noConsole: log
        console.warn(
          `Unable to find the variant selector element: ${this.selectedVariantSelector}`
        );

        this.loggedWarning = true;
      }

      return null;
    }

    if (el instanceof HTMLInputElement && el.type === 'radio') {
      const checkedRadioElement = document.querySelector<HTMLInputElement>(
        `${this.selectedVariantSelector}:checked`
      );
      return checkedRadioElement?.value;
    }

    if (el instanceof HTMLSelectElement || el instanceof HTMLInputElement) {
      return el.value;
    }

    return el.dataset.variantId ?? el.dataset.productId;
  }
}

function findLastVisible<T extends Element>(elems: NodeListOf<T>) {
  return Array.from(elems)
    .reverse()
    .find((e) => isVisible(e));
}

function last<T extends Element>(elems: NodeListOf<T>) {
  return elems[elems.length - 1];
}

export { AddToCartForm, SelectedVariant };
