export function findDeepestElementsMatching(
  el: HTMLElement,
  fn: (e: HTMLElement) => boolean
): HTMLElement[] {
  const matches: HTMLElement[] = [];
  for (const child of Array.from(el.children)) {
    if (child instanceof HTMLElement) {
      for (const match of findDeepestElementsMatching(child, fn)) {
        matches.push(match);
      }
    }
  }
  if (fn(el) && !matches.some((match) => isDescendant(el, match))) {
    matches.push(el);
  }
  return matches;
}

function isDescendant(parent: HTMLElement, child: HTMLElement): boolean {
  let node: HTMLElement | null = child;
  while (node) {
    if (node === parent) {
      return true;
    }

    node = node.parentElement;
  }
  return false;
}
