import getDeviceId from '@purple-dot/browser-sdk/src/device-id';
import * as SessionStorage from '@purple-dot/browser-sdk/src/libraries/session-storage';
import { FeatureFlags } from './feature-flags';
import {
  fetchVariantsPreorderState,
  NewEndpointPreorderState,
  VariantPreorderState,
} from './purple-dot-integration/backend';
import { InternalAnalytics } from './purple-dot-integration/internal-analytics';
import {
  ShopifyApi,
  ShopifyCartLineItem,
  ShopifyLineItemProperties,
} from './shopify-api';
import {
  AddToCartJSON,
  CartItem,
  makeRequestBody,
  parseRequestBody,
} from './shopify-theme/interceptors/interceptor';
import { ShopifyThemeListener } from './shopify-theme/shopify-theme';
import {
  getSellingPlanId,
  WaitlistAvailability,
} from './waitlist-availability';

function getCartLink() {
  const prefix = window.Shopify?.routes?.root || '/';
  return `${prefix}cart`;
}

export class CartTools implements ShopifyThemeListener {
  constructor(
    private shopifyApi: ShopifyApi,
    private waitlistAvailability: WaitlistAvailability,
    private featureFlags: FeatureFlags,
    private internalAnalytics: InternalAnalytics
  ) {}

  /*
    onCheckoutNavigation
    handles navigation from a tags that are clicked going to the checkout
  */
  public async onCheckoutNavigation(url: URL): Promise<URL> {
    const disallowCheckout = await this.disallowCheckout();

    if (disallowCheckout) {
      const cartLink = getCartLink();
      return new URL(cartLink, url);
    }

    return url;
  }

  /*
    onCheckoutSubmit
    intercepts and handles if the form with action /checkout is submitted
  */
  public async onCheckoutSubmit([input, init]: [
    input: string | URL,
    init?: RequestInit,
  ]): Promise<[input: string | URL, init?: RequestInit]> {
    const disallowCheckout = await this.disallowCheckout();

    if (init && disallowCheckout) {
      window.location.href = getCartLink();
      return ['NO_REDIRECT'];
    }

    return [input, init];
  }

  public async onAddToCart([input, init]: [
    input: string | URL,
    init?: RequestInit,
  ]): Promise<[input: string | URL, init?: RequestInit]> {
    if (!init || !init?.body) {
      return [input, init];
    }

    if (init.method?.toUpperCase() === 'POST' || init.body) {
      let newBody;

      try {
        newBody = parseRequestBody(init);
      } catch {
        // Unsupported body type, oh dear.
        return [input, init];
      }

      await this.fixAddToCart(newBody);

      return [input, { ...init, body: makeRequestBody(init, newBody) }];
    }

    if (init.method?.toUpperCase() === 'GET') {
      const newURL = new URL(input);
      await this.fixAddToCart(newURL.searchParams);
      return [newURL, init];
    }

    return [input, init];
  }

  public async onCartSubmit([input, init]: [
    input: string | URL,
    init?: RequestInit,
  ]): Promise<[input: string | URL, init?: RequestInit]> {
    const disallowCheckout = await this.disallowCheckout();
    if (disallowCheckout) {
      const newInput = new URL(input, window.location.href);
      for (const [key] of Array.from(newInput.searchParams)) {
        if (key.startsWith('checkout')) {
          newInput.searchParams.delete(key);
        }
      }

      if (init) {
        const body = parseRequestBody(init);
        if (body) {
          let mutated = false;

          if (body instanceof FormData || body instanceof URLSearchParams) {
            for (const key of Array.from(body.keys())) {
              if (key.startsWith('checkout')) {
                body.delete(key);
                mutated = true;
              }
            }
          } else {
            for (const key of Array.from(Object.keys(body))) {
              if (key.startsWith('checkout')) {
                delete body[key];
                mutated = true;
              }
            }
          }

          if (mutated) {
            return [newInput, { ...init, body: makeRequestBody(init, body) }];
          }
        }
      }

      return [newInput, init];
    }

    return [input, init];
  }

  public async onListenerAdded() {
    await this.getFixedCartItems();
  }

  public async disallowCheckout() {
    const cartItems = await this.getFixedCartItems();
    const compatibleCheckouts = await this.compatibleCheckouts(cartItems);

    if (!compatibleCheckouts.native) {
      return true;
    }

    if (!compatibleCheckouts.purpleDot) {
      return false;
    }

    const variation = this.featureFlags.variation('NATIVE_CHECKOUT');

    if (variation === 'purple_dot') {
      return true;
    }
    if (variation === 'native') {
      return false;
    }

    // By default, native checkout is enabled for all compatible carts
    return false;
  }

  private async compatibleCheckouts(
    cartItems: ShopifyCartLineItem[]
  ): Promise<{ native: boolean; purpleDot: boolean }> {
    const itemPreorderStates = await Promise.all(
      cartItems.map((item) => this.isPreorderItem(item))
    );

    const cartHasNoPreorderItems = !itemPreorderStates.some(
      (isPreorder) => isPreorder
    );
    if (cartHasNoPreorderItems) {
      return { purpleDot: false, native: true };
    }

    const cartIsMixed = !itemPreorderStates.every((isPreorder) => isPreorder);
    if (cartIsMixed) {
      return { purpleDot: true, native: false };
    }

    const someItemsNotCompatibleWithNativeCheckout = cartItems.some(
      (item) => !this.isItemCompatibleWithNativeCheckout(item)
    );

    if (someItemsNotCompatibleWithNativeCheckout) {
      return { purpleDot: true, native: false };
    }

    return { purpleDot: true, native: true };
  }

  private async isPreorderItem(item: ShopifyCartLineItem) {
    const sellingPlanId = item.selling_plan_allocation?.selling_plan?.id;
    if (
      sellingPlanId &&
      (await this.waitlistAvailability.isPdSellingPlan(sellingPlanId))
    ) {
      return true;
    }

    return !!item.properties?.__releaseId;
  }

  private isItemCompatibleWithNativeCheckout(item: ShopifyCartLineItem) {
    if (item.properties && '__pdCheckoutRequired' in item.properties) {
      const pdCheckoutRequired = item.properties
        .__pdCheckoutRequired as unknown as boolean | string;
      return pdCheckoutRequired === false || pdCheckoutRequired === 'false';
    }

    return false;
  }

  private isWaitlistCompatibleWithNativeCheckout(
    waitlist: VariantPreorderState['waitlist'] | undefined
  ) {
    const compatibleCheckouts = waitlist?.compatible_checkouts;
    if (!compatibleCheckouts) {
      return false;
    }
    return compatibleCheckouts.includes('native');
  }

  private async getFixedCartItems() {
    const cart = await this.shopifyApi.fetchCart();
    const cartItems: Record<string, ShopifyCartLineItem> = Object.fromEntries(
      cart.items.map((item) => [item.key, item])
    );

    // Get the total inventory available for every preorder variant in the cart.
    const cartAvailableStock =
      await this.waitlistAvailability.getCartAvailableStock(cart);

    let latestCartItems = cart.items;

    for (const { key } of cart.items) {
      const item = cartItems[key];
      const variantId = item.variant_id;

      const properties = await this.updateProperties(
        variantId,
        item.properties ?? {}
      );

      const currentSellingPlanId =
        item.selling_plan_allocation?.selling_plan?.id;
      const correctSellingPlanId = await this.getCorrectSellingPlanId(
        variantId,
        currentSellingPlanId
      );

      const currentQuantity = item.quantity;
      const correctQuantity = await this.getCorrectQuantity(
        item,
        cartAvailableStock
      );

      const changed =
        item.properties?.__releaseId !== properties.__releaseId ||
        item.properties?.['Purple Dot Pre-order'] !==
          properties['Purple Dot Pre-order'] ||
        item.properties?.['Purple Dot Payment Plan'] !==
          properties['Purple Dot Payment Plan'] ||
        item.properties?.__pdCheckoutRequired !==
          properties.__pdCheckoutRequired ||
        (correctSellingPlanId &&
          currentSellingPlanId !== correctSellingPlanId) ||
        (currentSellingPlanId &&
          currentSellingPlanId !== correctSellingPlanId) ||
        currentQuantity !== correctQuantity;

      if (changed) {
        const cartChange = await this.shopifyApi.changeCartItem(item.key, {
          properties,
          quantity: correctQuantity,
          sellingPlanId: correctSellingPlanId,
        });

        // changeCartItem may update other keys too, so we keep track of the latest version of each key
        // to avoid writing stale data to that line item.
        latestCartItems = cartChange.items;
        for (const newItem of cartChange.items) {
          cartItems[newItem.key] = newItem;
        }
      }
    }

    await this.logCheckoutCompatibility(latestCartItems);

    return latestCartItems;
  }

  private async newPdProperties(variantId: number) {
    const response = await fetchVariantsPreorderState(variantId);
    const waitlistsEnabled = await this.waitlistAvailability.waitlistsEnabled();

    if (
      response?.waitlist &&
      (response.state === NewEndpointPreorderState.OnPreorder ||
        response.state === NewEndpointPreorderState.SoldOut) &&
      waitlistsEnabled
    ) {
      const newProps: Record<string, any> = {
        __pdDebug: getDeviceId().deviceId,
        __releaseId: response.waitlist.id,
        __pdCheckoutRequired: !this.isWaitlistCompatibleWithNativeCheckout(
          response.waitlist
        ),
      };

      if (!response.waitlist.selling_plan_id) {
        if (response.waitlist.display_dispatch_date) {
          newProps['Purple Dot Pre-order'] =
            response.waitlist.display_dispatch_date;
        }
        if (response.waitlist.payment_plan_descriptions?.short) {
          newProps['Purple Dot Payment Plan'] =
            response.waitlist.payment_plan_descriptions.short;
        }
      }

      return newProps;
    }
    return null;
  }

  private async updateProperties(
    variantId: number,
    properties: ShopifyLineItemProperties
  ): Promise<ShopifyLineItemProperties> {
    const newPdProperties = await this.newPdProperties(variantId);

    const newProperties = { ...properties, ...newPdProperties };

    if (newPdProperties == null) {
      deleteKey(newProperties, '__releaseId');
      deleteKey(newProperties, 'Purple Dot Pre-order');
      deleteKey(newProperties, 'Purple Dot Payment Plan');
      deleteKey(newProperties, '__pdCheckoutRequired');
    }

    return newProperties;
  }

  private extractAddToCartProperties(
    data: FormData | URLSearchParams | CartItem
  ) {
    if ('id' in data) {
      return data.properties ?? {};
    }

    const properties: { [key: string]: string } = {};

    data.forEach((value, key) => {
      const keyMatch = key.match(/.*\[(.*)\]/);

      if (keyMatch && keyMatch.length > 0) {
        const propKey = keyMatch[1];
        properties[propKey] = value as string;
      }
    });

    return properties;
  }

  private async getCorrectSellingPlanId(
    variantId: number,
    currentSellingPlanId: number | null = null
  ) {
    if (
      currentSellingPlanId &&
      (await this.isNotPdSellingPlan(currentSellingPlanId))
    ) {
      return currentSellingPlanId;
    }

    const preorderState = await fetchVariantsPreorderState(variantId);

    if (preorderState?.state === NewEndpointPreorderState.OnPreorder) {
      const graphqlSellingPlanId = preorderState.waitlist?.selling_plan_id;
      if (graphqlSellingPlanId) {
        return getSellingPlanId(graphqlSellingPlanId);
      }
    }

    return null;
  }

  private async getCorrectQuantity(
    item: ShopifyCartLineItem,
    availableStock: Record<string, number>
  ) {
    let correctQuantity: number;

    if (await this.isPreorderItem(item)) {
      const unitsOnWaitlist = availableStock[item.variant_id];

      correctQuantity = Math.min(
        item.quantity,
        unitsOnWaitlist ?? item.quantity
      );

      // Subtract the cart inventory from the available amount.
      // If this becomes 0 we'll end up removing items from the cart.
      availableStock[item.variant_id] -= correctQuantity;
    } else {
      correctQuantity = item.quantity;
    }

    return correctQuantity;
  }

  private async isNotPdSellingPlan(sellingPlanId: number) {
    return !(await this.waitlistAvailability.isPdSellingPlan(sellingPlanId));
  }

  private async fixAddToCart(data: FormData | URLSearchParams | AddToCartJSON) {
    if (await this.fixObjectCartAdd(data)) {
      return;
    }

    if (await this.fixFormNestedCartAdd(data)) {
      return;
    }

    await this.fixFormSingleCartAdd(data);
  }

  private async fixObjectCartAdd(
    data: FormData | URLSearchParams | AddToCartJSON
  ) {
    // Fix the documented /cart/add.js API
    // https://shopify.dev/api/ajax/reference/cart

    if ('items' in data) {
      for (const item of data.items) {
        const newProps = await this.updateProperties(
          item.id,
          item.properties ?? {}
        );
        const correctSellingPlanId = await this.getCorrectSellingPlanId(
          item.id,
          item.selling_plan
        );

        item.properties = newProps;
        item.selling_plan = correctSellingPlanId;
      }

      return true;
    }

    return false;
  }

  private async fixFormNestedCartAdd(
    data: FormData | URLSearchParams | AddToCartJSON
  ) {
    // Fix the square[bracket] encoded multi-item /cart/add API
    // This seems to work because Shopify accept PHP/Rails style form data.

    if (!(data instanceof FormData || data instanceof URLSearchParams)) {
      return false;
    }

    const dataKeys = 'keys' in data ? Array.from(data.keys()) : [];
    let changed = false;

    for (const key of dataKeys) {
      const keyMatch = key.match(/items\[(.*)\]\[id\]/);

      if (keyMatch && keyMatch.length > 0) {
        changed = true;

        const itemIndex = Number.parseInt(keyMatch[1], 10);
        const variantId = Number.parseInt(data.get(key) as string, 10);

        const properties = {};
        let currentSellingPlanId: number | null = null;

        for (const key of dataKeys) {
          const propKeyMatch = key.match(
            `items\\[${itemIndex}\\]\\[properties\\]\\[(.*)\\]`
          );

          if (propKeyMatch && propKeyMatch.length > 0) {
            const propKey = propKeyMatch[1];
            properties[propKey] = data.get(key);
          }

          const sellingPlanKeyMatch = key.match(
            `items\\[${itemIndex}\\]\\[selling_plan\\]`
          );

          if (sellingPlanKeyMatch && sellingPlanKeyMatch.length > 0) {
            const sellingPlanKey = sellingPlanKeyMatch[0];
            currentSellingPlanId = Number.parseInt(
              data.get(sellingPlanKey) as string,
              10
            );
          }
        }

        const newProperties = await this.updateProperties(
          variantId,
          properties
        );

        const correctSellingPlanId = await this.getCorrectSellingPlanId(
          variantId,
          currentSellingPlanId
        );

        mergeNewProperties(data, newProperties, {
          releaseId: `items[${itemIndex}][properties][__releaseId]`,
          releaseDate: `items[${itemIndex}][properties][Purple Dot Pre-order]`,
          preorderPaymentPlanProp: `items[${itemIndex}][properties][Purple Dot Payment Plan]`,
          pdCheckoutRequiredProp: `items[${itemIndex}][properties][__pdCheckoutRequired]`,
        });

        if (correctSellingPlanId) {
          data.set(
            `items[${itemIndex}][selling_plan]`,
            correctSellingPlanId.toString()
          );
        }
      }
    }

    return changed;
  }

  private async fixFormSingleCartAdd(
    data: FormData | URLSearchParams | AddToCartJSON
  ) {
    // Fix the single item form style /cart/add API

    if ('items' in data) {
      return;
    }

    let variantId;

    if ('id' in data && data.id) {
      variantId = data.id;
    } else if ('get' in data) {
      variantId = data.get('id');
    }

    if (!variantId) {
      return;
    }

    const properties = this.extractAddToCartProperties(data);
    const newProperties = await this.updateProperties(variantId, properties);

    let currentSellingPlanId: number | null = null;
    if ('selling_plan' in data && data.selling_plan) {
      currentSellingPlanId = data.selling_plan;
    } else if ('get' in data) {
      currentSellingPlanId = Number.parseInt(
        data.get('selling_plan') as string,
        10
      );
    }
    const correctSellingPlanId = await this.getCorrectSellingPlanId(
      variantId,
      currentSellingPlanId
    );

    if (newProperties === null) {
      return;
    }

    if ('id' in data) {
      data.properties = newProperties;
      data.selling_plan = correctSellingPlanId;

      if (data.properties.__releaseId && 'checkout' in data) {
        deleteKey(data, 'checkout');
      }
    } else {
      mergeNewProperties(data, newProperties, {
        releaseId: 'properties[__releaseId]',
        releaseDate: 'properties[Purple Dot Pre-order]',
        preorderPaymentPlanProp: 'properties[Purple Dot Payment Plan]',
        pdCheckoutRequiredProp: 'properties[__pdCheckoutRequired]',
      });

      if (correctSellingPlanId) {
        data.set('selling_plan', correctSellingPlanId.toString());
      }
    }
  }

  private async logCheckoutCompatibility(cartItems: ShopifyCartLineItem[]) {
    if (!cartItems.length) {
      return;
    }

    const key = '__pdLastLoggedCompatibleCheckout';
    const compatibleCheckouts = await this.compatibleCheckouts(cartItems);
    const lastLoggedValue = SessionStorage.getItem(key);

    if (
      compatibleCheckouts.purpleDot !== lastLoggedValue?.purpleDot ||
      compatibleCheckouts.native !== lastLoggedValue?.native
    ) {
      SessionStorage.setItem(key, compatibleCheckouts);
      this.internalAnalytics.trackCheckoutCompatibilityChanged({
        purple_dot: compatibleCheckouts.purpleDot,
        native: compatibleCheckouts.native,
      });
    }
  }
}

function deleteKey(thing: any, key: string) {
  delete thing[key];
}

function mergeNewProperties(
  data: FormData | URLSearchParams,
  newProperties: Record<string, string>,
  propNames: {
    releaseId: string;
    releaseDate: string;
    preorderPaymentPlanProp: string;
    pdCheckoutRequiredProp: string;
  }
) {
  const {
    releaseId: releaseIdProp,
    releaseDate: releaseDateProp,
    preorderPaymentPlanProp,
    pdCheckoutRequiredProp,
  } = propNames;

  if (newProperties.__releaseId) {
    data.set(releaseIdProp, newProperties.__releaseId);
  } else {
    data.delete(releaseIdProp);
  }

  if (newProperties['Purple Dot Pre-order']) {
    data.set(releaseDateProp, newProperties['Purple Dot Pre-order']);
  } else {
    data.delete(releaseDateProp);
  }

  if (newProperties['Purple Dot Payment Plan']) {
    data.set(preorderPaymentPlanProp, newProperties['Purple Dot Payment Plan']);
  } else {
    data.delete(preorderPaymentPlanProp);
  }

  if ('__pdCheckoutRequired' in newProperties) {
    data.set(
      pdCheckoutRequiredProp,
      newProperties.__pdCheckoutRequired.toString()
    );
  } else {
    data.delete(pdCheckoutRequiredProp);
  }

  if (data.has(releaseIdProp)) {
    data.delete('checkout');
  }
}
