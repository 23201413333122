import { GetWaitlistsResponseItem } from '@purple-dot/main/src/presentation-layer/custom-server/api/public/views/get-waitlists-response';
import { ShopifyProduct } from '../shopify-api';
import { PreorderStateListener } from './preorder-add-to-cart-form';

class ButtonImpressions implements PreorderStateListener {
  onMount() {
    return;
  }

  onPreorder(
    product: ShopifyProduct,
    waitlist: GetWaitlistsResponseItem | null,
    variantId: number
  ) {
    if (waitlist) {
      this._trackImpression({
        state: 'AVAILABLE',
        product,
        waitlist,
        variantId,
      });
    }
  }

  inStock() {
    return;
  }

  soldOut(
    product: ShopifyProduct,
    waitlist: GetWaitlistsResponseItem | null,
    variantId: number
  ) {
    if (waitlist) {
      this._trackImpression({
        state: 'SOLD_OUT',
        product,
        waitlist,
        variantId,
      });
    }
  }

  unknownState() {
    return;
  }

  noVariantSelected() {
    return;
  }

  _trackImpression({
    state,
    product,
    waitlist,
    variantId,
  }: {
    state: string;
    product: ShopifyProduct;
    waitlist?: GetWaitlistsResponseItem;
    variantId: number;
  }) {
    window.PurpleDot.track.buttonImpression({
      productExternalId: `${product.id}`,
      skuExternalId: `${variantId}`,
      releaseId: waitlist?.id,
      buttonState: state,
    });
  }
}

export { ButtonImpressions };
