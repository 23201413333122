import { AddToCartForm } from '../shopify-theme/add-to-cart-form';
import { PreorderStateListener } from './preorder-add-to-cart-form';

export class WaitlistInfoRepositioner implements PreorderStateListener {
  onMount(addToCartForm: AddToCartForm) {
    let attempt = 0;
    const observer = new MutationObserver(() => {
      if (attempt >= 100) {
        observer.disconnect();
        return;
      }

      const formBtn = addToCartForm.getAddToCartButton()?.getElement();
      const waitlistInfo = addToCartForm.querySelector('#pd-waitlist-info');
      if (waitlistInfo && formBtn?.nextSibling !== waitlistInfo) {
        formBtn?.insertAdjacentElement('afterend', waitlistInfo);
      }

      attempt += 1;
    });
    observer.observe(addToCartForm.el, { childList: true, subtree: true });
  }

  onPreorder() {
    return;
  }

  inStock() {
    return;
  }

  soldOut() {
    return;
  }

  unknownState() {
    return;
  }

  noVariantSelected() {
    return;
  }
}
