import getDeviceId from '@purple-dot/browser-sdk/src/device-id';
import { debug } from 'debug';

const logger = debug('pd:captureError');

export function captureError(error: any) {
  try {
    const body = {
      deviceId: getDeviceId().deviceId,
      name: error.name,
      message: error.message,
      stack: error.stack,
      href: window.location.href,
      referrer: window.document.referrer,
    };

    logger('Captured error:', error);

    void logToPurpleDotApi(body);
  } catch (err) {
    logger('Error while trying to log an error!', err);
  }
}

export async function logToPurpleDotApi(body: any) {
  if (navigator.userAgent.includes('jsdom')) {
    return;
  }

  const url = new URL(`${window.PurpleDotConfig.hostURL}/api/v1/error`);

  if (window.PurpleDotConfig.apiKey) {
    url.searchParams.set('api_key', window.PurpleDotConfig.apiKey);
  }

  if (window.Shopify.shop) {
    url.searchParams.set('shop', window.Shopify.shop);
  }

  await fetch(url.toString(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
    keepalive: true,
  });
}
