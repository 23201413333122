/**
 * Selling Plan
 *
 * Controls the hidden input field used inside the
 * Add to Cart Form to track the selling plan id.
 *
 * e.g
 * <input type="hidden" name="selling_plan" value="<SELLING_PLAN_ID>" />
 */
export class SellingPlan {
  private readonly form!: HTMLElement;

  constructor({ form }: { form: HTMLElement }) {
    this.form = form;
  }

  upsert(newValue: string) {
    const inputEl = this._findSellingPlanInput();
    if (!inputEl) {
      const newEl = this._createSellingPlanInput(newValue);
      this.form.appendChild(newEl);
    } else {
      inputEl.value = newValue;
    }
  }

  remove() {
    const el = this._findSellingPlanInput();
    if (el) {
      el.parentElement?.removeChild(el);
    }
  }

  private _findSellingPlanInput(): HTMLInputElement | null {
    const selector = 'input[name="selling_plan"]';
    const inputEl = this.form.querySelector<HTMLInputElement>(selector);
    if (!inputEl) {
      return null;
    }

    return inputEl;
  }

  private _createSellingPlanInput(value: string) {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = 'selling_plan';
    input.value = value;
    return input;
  }
}
