import { CartFormSelectorConfig } from '../purple-dot-config';
import { AddToCartForm } from './add-to-cart-form';
import { AddToCartInterceptor } from './interceptors/add-to-cart-interceptor';
import { CartChangeInterceptor } from './interceptors/cart-change-interceptor';
import { CartInterceptor } from './interceptors/cart-interceptor';
import { CartUpdateInterceptor } from './interceptors/cart-update-interceptor';
import { CheckoutFormInterceptor } from './interceptors/checkout-form-interceptor';
import { CheckoutNavigationInterceptor } from './interceptors/checkout-navigation-interceptor';
import { isVisible } from './is-visible';
import { LoadingPage } from './loading-page';
import { SelectorObserver, SelectorObserverMode } from './selector-observer';
import * as UrlUtils from './url-utils';

/**
 * ShopifyTheme
 *
 * Entry point to the package. Acts as
 * an observer and event forwarder to any
 * listeners
 */

type FetchParams = [input: string | URL, init?: RequestInit];
export interface ShopifyThemeListener {
  onListenerAdded?: () => void;
  onPageLoaded?: () => void;
  onProductPageLoaded?: () => void;
  onNewAddToCartForm?: (addToCartForm: AddToCartForm) => void | Promise<void>;
  onNewCollectionItem?: ({
    gridItem,
    href,
    handle,
  }: {
    gridItem: HTMLElement;
    href: string;
    handle: string;
  }) => void;
  onNewCartForm?: (cart: HTMLElement) => void;
  onNewCheckoutLink?: (
    checkoutLink: HTMLElement,
    hasVisibleCartLink: boolean
  ) => void;
  onNewCheckoutButton?: (checkoutButton: HTMLElement) => void;
  onNewCheckoutOrPaymentElement?: (button: HTMLElement) => void;
  onNewStickyBar?: (bar: HTMLElement) => void;

  onCheckoutNavigation?: (url: URL) => Promise<URL>;
  onAddToCart?: (
    params: FetchParams,
    onComplete: Promise<unknown>
  ) => Promise<FetchParams | Response>;
  onCartUpdate?: (
    params: FetchParams,
    onComplete: Promise<unknown>
  ) => Promise<FetchParams>;
  onCartChange?: (
    params: FetchParams,
    onComplete: Promise<unknown>
  ) => Promise<FetchParams>;
  onCartSubmit?: (params: FetchParams) => Promise<FetchParams>;
  onCheckoutSubmit?: (params: FetchParams) => Promise<FetchParams>;
}

export class ShopifyTheme {
  collectionItemSelector?: string;
  productPageSelector?: string;
  selectedVariantSelector?: string;
  addToCartButtonSelector?: string;
  updateAddToCartButton?: boolean;
  listeners: ShopifyThemeListener[];
  cartFormSelector?: CartFormSelectorConfig | string;
  includeDefaultCollectionItemSelectors: boolean;
  checkoutButtonSelector?: string;

  addToCartInterceptor: AddToCartInterceptor;
  cartUpdateInterceptor: CartUpdateInterceptor;
  cartChangeInterceptor: CartChangeInterceptor;
  checkoutNavigationInterceptor: CheckoutNavigationInterceptor;
  cartInterceptor: CartInterceptor;
  checkoutFormInterceptor: CheckoutFormInterceptor;

  constructor({
    collectionItemSelector,
    productPageSelector,
    selectedVariantSelector,
    addToCartButtonSelector,
    updateAddToCartButton,
    cartFormSelector,
    includeDefaultCollectionItemSelectors = true,
    checkoutButtonSelector,
  }: {
    collectionItemSelector?: string;
    productPageSelector?: string;
    selectedVariantSelector?: string;
    addToCartButtonSelector?: string;
    updateAddToCartButton?: boolean;
    cartFormSelector?: CartFormSelectorConfig | string;
    includeDefaultCollectionItemSelectors?: boolean;
    checkoutButtonSelector?: string;
  }) {
    this.collectionItemSelector = collectionItemSelector;
    this.productPageSelector = productPageSelector;
    this.selectedVariantSelector = selectedVariantSelector;
    this.updateAddToCartButton = updateAddToCartButton;
    this.addToCartButtonSelector = addToCartButtonSelector;
    this.cartFormSelector = cartFormSelector;
    this.includeDefaultCollectionItemSelectors =
      includeDefaultCollectionItemSelectors;
    this.checkoutButtonSelector = checkoutButtonSelector;

    this.addToCartInterceptor = new AddToCartInterceptor();
    this.cartUpdateInterceptor = new CartUpdateInterceptor();
    this.cartChangeInterceptor = new CartChangeInterceptor();
    this.checkoutNavigationInterceptor = new CheckoutNavigationInterceptor();
    this.cartInterceptor = new CartInterceptor();
    this.checkoutFormInterceptor = new CheckoutFormInterceptor();

    this.listeners = [];

    this._startListeners = this._startListeners.bind(this);
  }

  listen() {
    const loadingPage = new LoadingPage();
    loadingPage.addListener(this);
    loadingPage.listen();
  }

  onDomContentLoaded() {
    this._startListeners();
  }

  _startListeners() {
    this._emit('onPageLoaded');

    if (UrlUtils.isProductPage()) {
      this._emit('onProductPageLoaded');
    }

    this._listenForProductForms();
    this._listenForCustomProductPages();
    this._listenForCollectionItems();
    this._listenForCartForms();
    this._listenForCheckoutLinks();
    this._listenForCheckoutButtons();
    this._listenForStickyBars();
    this._listenForCheckoutOrPaymentButtons();
  }

  _listenForProductForms() {
    const productFormListener = new SelectorObserver({
      selector: 'form[action$="/cart/add"]',
      mode: SelectorObserverMode.ON_FIRST_ENTERED_VIEWPORT,
    });

    productFormListener.listen((el) => {
      if (el instanceof HTMLElement) {
        // If a productPageSelector has been passed in
        // check that it doesn't contain a form otherwise
        // duplicate instances will be created, from LOCI incident

        if (this.productPageSelector) {
          const productPageElems = document.querySelectorAll(
            this.productPageSelector
          );

          const containsElem = Array.from(productPageElems).some((outerElem) =>
            outerElem.contains(el)
          );

          if (productPageElems.length && containsElem) {
            return;
          }
        }

        this._emit(
          'onNewAddToCartForm',
          new AddToCartForm({
            el,
            selectedVariantSelector: this.selectedVariantSelector,
            addToCartButtonSelector: this.addToCartButtonSelector,
          })
        );
      }
    });
  }

  _listenForCustomProductPages() {
    if (!this.productPageSelector) {
      return;
    }

    const productFormListener = new SelectorObserver({
      selector: this.productPageSelector,
      mode: SelectorObserverMode.ON_FIRST_ENTERED_VIEWPORT,
    });

    productFormListener.listen((el) => {
      if (el instanceof HTMLElement) {
        this._emit(
          'onNewAddToCartForm',
          new AddToCartForm({
            el,
            selectedVariantSelector: this.selectedVariantSelector,
            addToCartButtonSelector: this.addToCartButtonSelector,
          })
        );
      }
    });
  }

  _listenForCollectionItems() {
    const DEFAULT_SELECTORS = ['.grid-item', '.grid__item', '.Grid__Cell'];

    let selectors: string[];

    if (this.includeDefaultCollectionItemSelectors) {
      selectors = this.collectionItemSelector
        ? [...DEFAULT_SELECTORS, this.collectionItemSelector]
        : DEFAULT_SELECTORS;
    } else {
      selectors = this.collectionItemSelector
        ? [this.collectionItemSelector]
        : [];
    }

    const elementsBeingObserved = new Set<HTMLElement>();

    const gridItemListener = new SelectorObserver({
      selector: joinSelectors(selectors),
      mode: SelectorObserverMode.ON_SHOWN,
    });

    const onNewCollectionItem = (gridItemEl: HTMLElement) => {
      const href = this._getPDPLink(gridItemEl);
      if (href) {
        const handle = UrlUtils.extractHandle(href);

        if (handle) {
          this._emit('onNewCollectionItem', {
            gridItem: gridItemEl,
            href,
            handle,
          });
        }
      } else if (
        hasNoHrefsOnAnchors(gridItemEl) &&
        !elementsBeingObserved.has(gridItemEl)
      ) {
        // If the gridItemEl has anchors but no hrefs, then it's likely
        // that the href is being set by JS. In this case, we should
        // retry to identify the href
        // add a mutation observer on the element to listen for href changes
        const observer = new MutationObserver(() => {
          if (!hasNoHrefsOnAnchors(gridItemEl)) {
            observer.disconnect();
            elementsBeingObserved.delete(gridItemEl);
            onNewCollectionItem(gridItemEl);
          }
        });

        observer.observe(gridItemEl, {
          childList: true,
          subtree: true,
          attributes: true,
        });

        elementsBeingObserved.add(gridItemEl);
      }
    };

    gridItemListener.listen(onNewCollectionItem);
  }

  _listenForCartForms() {
    const DEFAULT_SELECTORS = [
      'form[action*="/cart"]:not([action*="/add"])',
      'form[action*="/checkout"]',
      '.zrx-cart-wrapper',
      '.rebuy-cart__flyout',
      '#shopify-section-cart-drawer .drawer__container',
    ];

    let selectors: string[];

    if (
      typeof this.cartFormSelector === 'object' &&
      !Array.isArray(this.cartFormSelector) &&
      this.cartFormSelector !== null
    ) {
      if (this.cartFormSelector.ignoreDefault) {
        selectors = this.cartFormSelector.additionalSelectors || [];
      } else {
        selectors = [
          ...DEFAULT_SELECTORS,
          ...(this.cartFormSelector.additionalSelectors || []),
        ];
      }
    } else if (typeof this.cartFormSelector === 'string') {
      selectors = [...DEFAULT_SELECTORS, this.cartFormSelector];
    } else {
      selectors = DEFAULT_SELECTORS;
    }

    if (selectors.length === 0) {
      return;
    }

    const cartFormListener = new SelectorObserver({
      selector: joinSelectors(selectors),
      mode: SelectorObserverMode.ON_SHOWN,
    });

    cartFormListener.listen((cart) => {
      const action = cart.getAttribute('action') ?? '';
      const actionURL = new URL(action, window.location.href);

      if (actionURL.origin === window.location.origin) {
        this._emit('onNewCartForm', cart);
      }
    });
  }

  _listenForCheckoutLinks() {
    const listener = new SelectorObserver({
      selector: 'a[href*="/checkout"]',
      mode: SelectorObserverMode.ON_SHOWN,
    });

    listener.listen((el) => {
      this._emit('onNewCheckoutLink', el, this.hasVisibleCartLink(el));
    });
  }

  _listenForCheckoutButtons() {
    const selectors = [
      'button[onclick*="window.location=\'/checkout\'"]',
      '#mu-checkout-button',
      'button.cart-summary__checkout',
      '.rebuy-cart__checkout-button',
    ];

    if (this.checkoutButtonSelector) {
      selectors.push(this.checkoutButtonSelector);
    }

    const listener = new SelectorObserver({
      selector: joinSelectors(selectors),
      mode: SelectorObserverMode.ON_SHOWN,
    });

    listener.listen((el) => {
      this._emit('onNewCheckoutButton', el);
    });
  }

  _listenForStickyBars() {
    const listener = new SelectorObserver({
      selector: '#gfp_shopper_toolbar',
      mode: SelectorObserverMode.ON_SHOWN,
    });

    listener.listen((el) => {
      this._emit('onNewStickyBar', el);
    });
  }

  _listenForCheckoutOrPaymentButtons() {
    /**
     * Hide any quick checkout or payment buttons if there is a pre-order in the cart.
     *
     * Slipthroughs can be happen if a shopper adds a preorder item to the cart,
     * navigates to another page and then uses a quick checkout option.
     *
     * This mitigates that issue by checking the state of the cart when a new add to cart
     * form is shown and then hiding the quick checkout options if there is a preorder item in the cart
     *
     * This does not eliminate slipthroughs entirely as there will be a period of time
     * between requesting the cart where the user could click this button.
     */
    const listener = new SelectorObserver({
      selector: [
        // Shopify
        '.shopify-payment-button',
        '[data-shopify="dynamic-checkout-cart"]',
        '.shoppay-message',
        // Klarna
        '.cart__klarna',
        'klarna-placement',
        'deliverr-tag-extended',
        '[id*="klarna"]',
        '[class*="klarna"]',
        // Afterpay
        'afterpay-placement',
        '[id*="afterpay"]',
        '[class*="afterpay"]',
        // Upcart
        '.upcart-express-pay-button',
        '.upcart-trust-badge',
        // Rebuy
        '.rebuy-cart__shop-pay-button',
        '.rebuy-cart__flyout-discount-container',
        // Sezzle
        '[id*="sezzle"]',
        '[class*="sezzle"]',
        // Misc.
        '.additional-checkout-buttons',
        '.cart-sidebar-discount',
        '.affirm-note',
        '.summary-bottom_row.is-member-price', // Don't show Bandit Running membership subscription option
        '.hs-content-buttons redo-shopify-toggle', // Don't show Redo returns for Nui Organics
      ].join(', '),
      mode: SelectorObserverMode.ON_SHOWN,
    });

    listener.listen((el) => {
      this._emit('onNewCheckoutOrPaymentElement', el);
    });
  }

  _getPDPLink(gridItemEl: Element) {
    const hrefSelector = 'a[href*="/products/"]';
    const productLink =
      gridItemEl.querySelector<HTMLAnchorElement>(hrefSelector);
    if (!productLink) {
      return null;
    }

    if (productLink.hostname !== window.location.hostname) {
      return null;
    }

    if (productLink.pathname.match(/\.(jpe?g|png|gif)$/)) {
      return null;
    }

    return productLink.href;
  }

  private hasVisibleCartLink(relativeToCheckoutLink: HTMLElement) {
    const cartLinksContainer =
      this.findParentCartElement(relativeToCheckoutLink) ?? document;
    const cartLinks = cartLinksContainer.querySelectorAll('a[href$="/cart"]');
    return Array.from(cartLinks).some((el) => isVisible(el));
  }

  private findParentCartElement(el: HTMLElement): HTMLElement | null {
    // Walk up the DOM trying to find the cart drawer containing the given element
    // TODO make these selectors configurable in PurpleDotConfig
    const cartDrawerSelectors = [
      'ajax-cart',
      'cart-drawer',
      '.cart-drawer',
      '.side-cart',
      '.cart-drawer-modal',
      '.slidecart-wrapper',
    ];

    let parentEl = el.parentElement;
    while (parentEl) {
      for (const selector of cartDrawerSelectors) {
        if (parentEl.matches(selector)) {
          return parentEl;
        }
      }
      parentEl = parentEl.parentElement;
    }
    return null;
  }

  _emit<
    E extends keyof ShopifyThemeListener,
    A extends Parameters<Required<ShopifyThemeListener>[E]>,
  >(event: E, ...args: A) {
    for (const listener of this.listeners) {
      const handler: undefined | ((...any) => void) = listener[event];

      if (handler) {
        handler.call(listener, ...args);
      }
    }
  }

  addListener(listener: ShopifyThemeListener) {
    this.listeners.push(listener);

    if (listener.onListenerAdded) {
      listener.onListenerAdded();
    }

    this.intercept(listener);
  }

  private intercept(interceptor: ShopifyThemeListener) {
    if (interceptor.onAddToCart) {
      this.addToCartInterceptor.addHandler(
        interceptor.onAddToCart.bind(interceptor)
      );
    }

    if (interceptor.onCartUpdate) {
      this.cartUpdateInterceptor.addHandler(
        interceptor.onCartUpdate.bind(interceptor)
      );
    }

    if (interceptor.onCartChange) {
      this.cartChangeInterceptor.addHandler(
        interceptor.onCartChange.bind(interceptor)
      );
    }

    if (interceptor.onCheckoutNavigation) {
      this.checkoutNavigationInterceptor.addHandler(
        interceptor.onCheckoutNavigation.bind(interceptor)
      );
    }

    if (interceptor.onCartSubmit) {
      this.cartInterceptor.addHandler(
        interceptor.onCartSubmit.bind(interceptor)
      );
    }

    if (interceptor.onCheckoutSubmit) {
      this.checkoutFormInterceptor.addHandler(
        interceptor.onCheckoutSubmit.bind(interceptor)
      );
    }
  }
}

function hasNoHrefsOnAnchors(el: Element) {
  const anchors = el.querySelectorAll('a');
  return Array.from(anchors).every((a) => !a.href) && anchors.length > 0;
}

function joinSelectors(selectors: string[]) {
  return selectors.join(',');
}
