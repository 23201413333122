import { FeatureFlags } from '../feature-flags';
import { ShopifyApi, ShopifyProduct } from '../shopify-api';
import { AddToCartForm } from '../shopify-theme/add-to-cart-form';
import { parseRequestBody } from '../shopify-theme/interceptors/interceptor';
import { ShopifyThemeListener } from '../shopify-theme/shopify-theme';
import { fetchVariantsPreorderState } from './backend';

export class InternalAnalytics implements ShopifyThemeListener {
  constructor(
    private shopifyApi: ShopifyApi,
    private featureFlags: FeatureFlags
  ) {}

  async onNewAddToCartForm(addToCartForm: AddToCartForm) {
    let product = await this._getProductForVariant(
      addToCartForm.getVariantId(),
      addToCartForm.getHandle()
    );

    if (product) {
      this._trackProductViewed(product);

      const variantId = addToCartForm.getVariantId();
      if (variantId) {
        await this._trackVariantSelected(variantId);
      }
    }

    addToCartForm.onVariantIdChange(async (variantId) => {
      if (!variantId) {
        return;
      }

      if (!product) {
        product = await this._getProductForVariant(variantId);
        if (product) {
          this._trackProductViewed(product);
        }
      }

      await this._trackVariantSelected(variantId);
    });
  }

  onAddToCart(
    [input, init]: [input: string | URL, init?: RequestInit],
    onComplete: Promise<unknown>
  ): Promise<[input: string | URL, init?: RequestInit]> {
    void onComplete.then(() => {
      if (init) {
        const body = parseRequestBody(init);

        if ('items' in body) {
          for (const item of body.items) {
            this.trackAddedToCart({
              id: item.id.toString(),
              releaseId: item.properties?.__releaseId,
            });
          }
        } else if (
          body instanceof URLSearchParams ||
          body instanceof FormData
        ) {
          const idField = body.get('id') as string;

          if (idField) {
            this.trackAddedToCart({
              id: idField,
              releaseId: body.get('properties[__releaseId]')?.toString(),
            });
          }
        } else if ('id' in body) {
          this.trackAddedToCart({
            id: body.id.toString(),
            releaseId: body.properties?.__releaseId,
          });
        }
      }
    });

    return Promise.resolve([input, init]);
  }

  trackCheckoutCompatibilityChanged(
    compatibleCheckouts: Record<string, boolean>
  ) {
    window.PurpleDot?.track.raw('integration.checkout_compatibility_changed', {
      compatible_checkouts: compatibleCheckouts,
      feature_flags: this.featureFlags.allVariations(),
    });
  }

  private trackAddedToCart({
    id,
    releaseId,
  }: {
    id: string;
    releaseId?: string;
  }) {
    window.PurpleDot?.track.raw('integration.added_to_cart', {
      id,
      release_id: releaseId,
      feature_flags: this.featureFlags.allVariations(),
    });
  }

  private _trackProductViewed(product: ShopifyProduct) {
    window.PurpleDot?.track.productViewed({
      productId: `${product.id}`,
      available: product.available,
      skus: product.variants.map((variant) => ({
        skuId: `${variant.id}`,
        available: variant.available,
        stockQuantity: variant.inventory_quantity,
      })),
      featureFlags: this.featureFlags.allVariations(),
    });
  }

  private async _trackVariantSelected(variantId: number) {
    const state = await fetchVariantsPreorderState(variantId);
    if (state?.product.handle) {
      const shopifyProduct = await this.shopifyApi.fetchProduct(
        state.product.handle
      );
      const shopifyVariant = shopifyProduct?.variants.find(
        (v) => v.id === variantId
      );
      window.PurpleDot?.track.skuSelected({
        productId: shopifyProduct ? `${shopifyProduct.id}` : null,
        skuId: `${variantId}`,
        available: shopifyVariant?.available,
        stockQuantity: shopifyVariant?.inventory_quantity,
        state,
        releaseId: state?.waitlist?.id,
        featureFlags: this.featureFlags.allVariations(),
      });
    }
  }

  private async _getProductForVariant(
    variantId?: number | null,
    handle?: string | null
  ) {
    let productHandle = handle;

    if (!productHandle && variantId) {
      const state = await fetchVariantsPreorderState(variantId);
      productHandle = state?.product.handle;
    }

    if (productHandle) {
      return await this.shopifyApi.fetchProduct(productHandle);
    }

    return null;
  }
}
