import { captureError } from '../../debug/capture-error';
import { formDataFromSubmit } from '../../form-data-from-submit';
import { submitFormData } from '../../submit-form-data';
import { shopifyUrlStartsWith } from './interceptor';
import { Callback, RequestInterceptor } from './request-interceptor';

type FetchParams = [input: string | URL, init?: RequestInit];

function shouldIntercept(input: string | URL) {
  return shopifyUrlStartsWith(input, 'cart/add');
}

function interceptSubmit(cb: Callback) {
  function submitListener(event: SubmitEvent) {
    if (
      event.target &&
      !event.defaultPrevented &&
      event.target instanceof HTMLElement &&
      !event.target.classList.contains('pd-submit-intercept')
    ) {
      const form = event.target as HTMLFormElement;
      const request: FetchParams = [
        new URL(form.action, window.location.href),
        { method: form.method ?? 'get', body: formDataFromSubmit(event) },
      ];

      if (shouldIntercept(request[0])) {
        event.preventDefault();

        // This never resolves because submitting the form navigates away from the page.
        const onComplete = new Promise(() => undefined);

        try {
          cb(request, onComplete)
            .then((newRequest) => {
              if (newRequest instanceof Response) {
                return newRequest;
              }

              return submitFormData(newRequest);
            })
            .catch((error) => {
              void captureError(error);

              submitFormData(request);
            });
        } catch (error: any) {
          void captureError(error);

          submitFormData(request);
        }
      }
    }
  }

  window.addEventListener('submit', submitListener);
}

export class AddToCartInterceptor extends RequestInterceptor {
  constructor() {
    super(shouldIntercept);

    const chain = this.chainHandlers.bind(this);
    interceptSubmit(chain);
  }
}
