export const Coral = '#FFA87C';

export const Lavendar = '#C2AAE3';

export const LavendarTint = '#F3EEFA';

export const Purple = '#8F17D1';

export const Navy = '#130228';

export const White = '#FFFFFF';

export const Green = '#B1DEC9';

export const Grey = '#F0EFEE';

export const Grey2 = '#D8D8D8';

export const Grey3 = '#13022899';

export const Grey4 = '#EAECF0';

export const Red = '#CF2F20';

export const Black = '#000000';

export const Blue = '#007bff';
