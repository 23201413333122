import { AddToCartForm } from '../shopify-theme/add-to-cart-form';

class Elements {
  atcForm!: AddToCartForm;
  selectors!: string[];
  state!: string;

  constructor({
    atcForm,
    selectors = [],
  }: {
    atcForm: AddToCartForm;
    selectors?: string[];
  }) {
    this.atcForm = atcForm;
    this.selectors = selectors;

    setInterval(() => {
      for (const selector of this.selectors) {
        const el = this._findElement(selector);
        if (!el) {
          continue;
        }

        if (this.state === 'HIDE') {
          this._hideElement(el);
        } else if (this.state === 'SHOW') {
          this._showElement(el);
        }
      }
    }, 50);
  }

  show() {
    this.state = 'SHOW';
  }

  hide() {
    this.state = 'HIDE';
  }

  _showElement(_el: HTMLElement) {
    throw new Error('Not implemented');
  }

  _hideElement(_el: HTMLElement) {
    throw new Error('Not implemented');
  }

  _findElement(selector: string) {
    let el = this.atcForm.querySelector(selector);
    if (!el) {
      el = this.atcForm.querySelectorSurroundingElements(selector);
    }

    return el;
  }
}

/**
 * ElementsToHide
 *
 * Simple interface to control the visibility
 * of elements and keep them hidden.
 *
 * This will stop other third party scripts from
 * re-showing or re-hiding elements once they have
 * been set to hidden;
 */
class ElementsToHide extends Elements {
  constructor({
    atcForm,
    selectors,
  }: {
    atcForm: AddToCartForm;
    selectors?: string[];
  }) {
    super({ atcForm, selectors });
    this.injectStyle();
  }

  injectStyle() {
    const styles = document.createElement('style');

    styles.innerHTML = `
      .pd-hide {
        display: none !important;
      }
    `;

    this.atcForm.getElement().appendChild(styles);
  }

  _showElement(el: HTMLElement) {
    el.classList.remove('pd-hide');
  }

  _hideElement(el: HTMLElement) {
    el.classList.add('pd-hide');
  }
}

class ElementsToShow extends Elements {
  _showElement(el: HTMLElement) {
    el.style.display = el.dataset.pdDisplay || '';
  }

  _hideElement(el: HTMLElement) {
    if (!('pdDisplay' in el.dataset)) {
      el.dataset.pdDisplay = el.style.display;
    }

    el.style.display = 'none';
  }
}

export { ElementsToHide, ElementsToShow };
